import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

class OOUserSignIn extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
	    super(props);
    
		// OutcomeOwl customization.  Setup for form data
		this.state = {
			ooformEmailAddress: "",
			ooformPassword: ""
		};

	    this.handleSubmit = this.handleSubmit.bind(this);
	    	this.state = {
    		redirect: false
	    }
	  }
  
	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');

		// OutcomeOwl customization
		// Set Environment URL.  Do this once at Sign In.
		// Destroy other variables in local storage
		var ooEnvironmentURL, ooApacheParamEnvURL;
		ooApacheParamEnvURL = process.env.OUTCOMEOWL_ENVIRONMENT_URL;
		if (ooApacheParamEnvURL) {
			ooEnvironmentURL = "https://" + ooApacheParamEnvURL + "/api";
		}
		else {
			ooEnvironmentURL = "https://dev.outcomeowl.com/api";
		}
		localStorage.setItem("ooEnvironmentURL", ooEnvironmentURL);
		localStorage.setItem("ooAPIKey", "");
		localStorage.setItem("ooSecurityRole", "");
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}
	
	// OutcomeOwl customization.  Setup for form data
	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value
		});
	};

	handleSubmit(event) {
		event.preventDefault();

		// OutcomeOwl customization.  Authenticate, create session, save in local storage
		const ooEnvironmentURL = localStorage.getItem("ooEnvironmentURL");
		var ooAPIKey, ooMessage, ooSecurityRole;
		var { ooformEmailAddress, ooformPassword } = this.state;
		if (ooformEmailAddress && ooformPassword) {

			const APIURL = ooEnvironmentURL + "/v1/authbasic";

			const data = {
				UserName: ooformEmailAddress,
				UserPassword: ooformPassword			
			};

			fetch(APIURL, {
				method: 'POST',
				headers: {
					'accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			})
			.then(response => response.json())
			.then(responseData => {
				ooAPIKey = responseData.APIKey;
				ooSecurityRole = responseData.SecurityRole;
				ooMessage = responseData.Message;
// FIXME evaluate for Password Change using Message
// Must build new page to navigate to for PW change
				if (typeof ooAPIKey !== 'undefined' && ooAPIKey.length == 36) {
					localStorage.setItem("ooAPIKey", ooAPIKey);
					localStorage.setItem("ooSecurityRole", ooSecurityRole);
					this.setState(state => ({
						redirect: true
					}));
				}
				else {
					this.setState(state => ({
						redirect: false
					}));
				}
			})
			.catch(error => {
				console.log('Error: ', error);
			})
		} else {
			this.setState(state => ({
				redirect: false
			}));
		}
	}
  
	render() {
		if (this.state.redirect) {
			return <Navigate to='/ooanalysis/oodashboard'/>;
	 	}
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{backgroundImage: 'url(/assets/img/login-bg/login-bg-11.jpg)'}}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Recent Updates</b></h4>
						<p>
							coming soon
						</p>
					</div>
				</div>
				<div className="login-container">
					<div className="login-header mb-30px">
						<div className="brand">
							<div className="d-flex align-items-center">
								<span className="logo"></span>
							
							
								<b>Outcome Owl</b>
							</div>
							<small></small>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in-alt"></i>
						</div>
					</div>
					<div className="login-content">
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="form-floating mb-15px">
								<input type="email" className="form-control h-45px fs-13px" placeholder="Email Address" minlength="6" name="ooformEmailAddress" id="ooformEmailAddress" value={this.state.ooformEmailAddress} onChange={this.handleChange} />
								<label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
							</div>
							<div className="form-floating mb-15px">
								<input type="password" className="form-control h-45px fs-13px" placeholder="Password" minlength="8" name="ooformPassword" id="ooformPassword" value={this.state.ooformPassword} onChange={this.handleChange} />
								<label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Password</label>
							</div>
							<div className="mb-15px">
								<button type="submit" className="btn btn-success d-block h-45px w-100 btn-lg fs-14px">Sign In</button>
							</div>
							<hr className="bg-gray-600 opacity-2" />
							<div className="text-gray-600 text-center text-gray-500-darker mb-0">
								&copy; Outcome Owl 2023, All Rights Reserved
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default OOUserSignIn;